import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import Hero from "../../components/hero/hero";
import Portfolio from "../../components/portfolio/portfolio";
import Quote from "../../components/quote/quote";
import Timeline from "../../components/timeline/timeline";
import Contact from "../contact/contact";

const App: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="fadeInLoad">
      <Header />
      <main>
        <Hero />
        <Quote title={t("about.title") as string} text={t("about.text")} />
        <Portfolio />
        <Quote text={t("quote.1")} />
        <Timeline />
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default App;
