import { useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { AllSkills, Skills } from "../../data/skills";
import imageProfile from "../../images/profile.png";
import Quote from "../quote/quote";
import SocialsList from "../socials-list/socials-list";
import "./contact.css";

const Contact: React.FC = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string | null>(null);

  return (
    <section id="contact" className="contact-section">
      <Quote title={t("contact.title") as string} text={t("contact.text")} />
      <div className="profile">
        <div className="container">
          <div className="info">
            <img src={imageProfile} />
            <a
              className="button button-fill"
              href="mailto:contact@jurwind.nl"
              target="_blank"
              rel="noreferrer"
            >
              {t("button.contact")}
            </a>
            <SocialsList />
          </div>
          <div className="skills">
            <div className="title">
              <h4>{t("skills.title")}</h4>
            </div>
            <div className="links">
              <ul>
                <li>
                  <a
                    className={!filter ? "active" : ""}
                    onClick={() => setFilter(null)}
                  >
                    {t("filter.all")}
                  </a>
                </li>
                {Object.keys(Skills).map((item, index) => (
                  <li key={"skill_header_" + index}>
                    <a
                      className={item == filter ? "active" : ""}
                      onClick={() => setFilter(item)}
                    >
                      {t(`skill.${item}`)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="list">
              {AllSkills.map((item) => (
                <div
                  key={uuid()}
                  className={`skill ${
                    !filter || Skills[filter].includes(item)
                      ? "visable"
                      : "gone"
                  }`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
