import { Fragment, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { Project, ProjectType, Projects } from "../../data/projects";
import imageButtonAppStore from "../../images/button_app_store.png";
import imageButtonPlayStore from "../../images/button_google_play.png";
import imageButtonWeb from "../../images/button_web.png";
import "./portfolio.css";

enum SortOption {
  Relevance = "relevance",
  Year = "year",
  Status = "status",
  Type = "type",
}

const Portfolio: React.FC = () => {
  const { t } = useTranslation();
  const [sort, setSort] = useState<SortOption>(SortOption.Relevance);

  let projects: Project[] = [];
  switch (sort) {
    case SortOption.Relevance:
      projects = [...Projects];
      break;
    case SortOption.Year:
      projects = [...Projects].sort((a, b) => b.year - a.year);
      break;
    case SortOption.Status:
      projects = [...Projects].sort();
      break;
    case SortOption.Type:
      projects = [...Projects].sort();
      break;
  }

  return (
    <section id="portfolio" className="portfolio">
      <div className="container">
        <div className="title">
          <h1>{t("portfolio.title")}</h1>
        </div>
        <div className="links">
          <ul>
            {Object.values(SortOption).map((option) => (
              <li key={uuidv4()}>
                <a
                  onClick={() => setSort(option)}
                  className={sort == option ? "active" : ""}
                >
                  {t(`filter.${option}`)}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="projects">
          {projects.map((project, index) => (
            <Fade
              key={uuidv4()}
              direction="up"
              triggerOnce={true}
              className="project"
              delay={index * 100}
            >
              <div className="item">
                <div className="info">
                  <div className="thumbnail">
                    <div className="date">
                      <div className="year">{project.year}</div>
                    </div>
                    <img src={project.image} />
                  </div>
                  <div className="content">
                    <div className={`status status-${project.status}`}>
                      {t(`project.${project.status}`)}
                    </div>
                    <h2 className="title">
                      {t(`project.${project.id}.title`)}
                    </h2>
                    <h3 className="description">
                      {t(`project.${project.id}.summary`)}
                    </h3>
                  </div>
                </div>
                <div className="buttons">
                  {project.type == ProjectType.Web && (
                    <Fragment>
                      <div className={`${!project.url ? "inactive" : ""}`}>
                        <a href={project.url} target="_blank" rel="noreferrer">
                          <img src={imageButtonWeb} width="100%" />
                        </a>
                      </div>
                    </Fragment>
                  )}
                  {project.type == ProjectType.Mobile && (
                    <Fragment>
                      <div
                        className={`${
                          !project.googlePlayStore ? "inactive" : ""
                        }`}
                      >
                        <a
                          href={project.googlePlayStore}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imageButtonPlayStore} width="100%" />
                        </a>
                      </div>
                      <div
                        className={`${
                          !project.appleAppStore ? "inactive" : ""
                        }`}
                      >
                        <a
                          href={project.appleAppStore}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imageButtonAppStore} width="100%" />
                        </a>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
