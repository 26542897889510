export enum SkillType {
  Frontend = "frontend",
  Backend = "backend",
  Devops = "devops",
  Lang = "language",
  OS = "os",
  Tools = "tools",
  UXUI = "uxui",
}

export const Skills: { [key: string]: string[] } = {
  [SkillType.Frontend]: [
    "React",
    "NextJS",
    "HTML",
    "CSS",
    "Sass",
    "jQuery",
    "Bootstrap",
    "Tailwind",
  ],
  [SkillType.Backend]: [
    "NodeJS",
    "Express",
    "PHP",
    "MYSQL",
    "Symfony 2",
    "NGINX",
    "Memcached",
    "Redis",
  ],
  [SkillType.Lang]: [
    "Java",
    "Kotlin",
    "Swift",
    "C#",
    "JavaScript (ES6+)",
    "Typescript",
    "ActionScript",
  ],
  [SkillType.OS]: ["Windows", "MacOS", "Linux"],
  [SkillType.Devops]: [
    "GIT",
    "GitHub",
    "npm",
    "yarn",
    "Jira",
    "AWS",
    "GCP",
    "Firebase",
    "AdMob",
    "StoryBlok",
  ],
  [SkillType.UXUI]: [
    "Photoshop",
    "Illustrator",
    "InDesign",
    "XD",
    "Figma",
    "Davinci Resolve",
  ],
  [SkillType.Tools]: [
    "Android Studio",
    "XCode",
    "Intellij",
    "Visual Studio Code",
    "Unity",
  ],
};

export const AllSkills = Object.values(Skills).reduce((total, item) =>
  total.concat(item)
);
