import React, { ReactNode, useEffect, useState } from "react";

export enum Theme {
  Light = "light",
  Dark = "dark",
}

const initialState = {
  theme: Theme.Light,
  toggle: () => {
    //
  },
};

interface ThemeProviderProps {
  children?: ReactNode | undefined;
}

const ThemeContext = React.createContext(initialState);

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const matchMedia = window.matchMedia("(prefers-color-scheme: light)");
  const [theme, setTheme] = useState<Theme>(
    (localStorage.getItem("theme") as Theme) ??
      (matchMedia.matches ? Theme.Light : Theme.Dark)
  );

  useEffect(() => {
    matchMedia.addEventListener("change", handleChange);
    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add(
      `theme-${theme == Theme.Light ? "light" : "dark"}`
    );
    document.body.classList.remove(
      `theme-${theme == Theme.Dark ? "light" : "dark"}`
    );
  }, [theme]);

  const handleChange = () => {
    if (localStorage.getItem("theme") == null) {
      setTheme(matchMedia.matches ? Theme.Light : Theme.Dark);
    }
  };

  const toggle = () => {
    const newTheme = theme == Theme.Light ? Theme.Dark : Theme.Light;
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggle }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
