import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { TimelineList } from "../../data/timeline";
import "./timeline.css";

const Timeline: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section id="timeline" className="timeline">
      <div className="container">
        <div className="title">
          <h1>{t("timeline.title")}</h1>
        </div>
        <div className="list">
          <ul>
            {TimelineList.map((time, index) => (
              <Fade
                key={index}
                triggerOnce={true}
                delay={index * 250}
                direction={index % 2 == 0 ? "left" : "right"}
              >
                <li>
                  <div className="image">
                    <img src={time.image} />
                  </div>
                  <div className="text">
                    <h6>{time.year}</h6>
                    <h4>{time.role}</h4>
                    <h5>
                      {t(`timeline.title.${time.title}`, {
                        company: time.company,
                      })}
                    </h5>
                    <div className="skills">
                      {time.skills.map((skill, index) => (
                        <div key={"skill_" + index} className="skill">
                          {skill}
                        </div>
                      ))}
                    </div>
                  </div>
                </li>
              </Fade>
            ))}
          </ul>
          <div className="footer">
            <h4
              dangerouslySetInnerHTML={{
                __html: t("timeline.tagline"),
              }}
            ></h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Timeline;
