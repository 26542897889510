export enum Title {
  Employee = "employee",
  SelfEmployed = "selfemployed",
  CoFounder = "cofounder",
}

export const TimelineList = [
  {
    role: "Frontend Developer",
    company: "BlueReaction",
    title: Title.SelfEmployed,
    year: "2009 - 2010",
    summary:
      "Na mijn opleiding werd ik gevraagd om op projectbasis bij United Games een platform te ontwikkelen rondom de KLM Open 2011. <br /> <br /> Ik was verantwoordelijk voor het omzetten van het ontwerp naar een goed functionerende website en het aansluiten van front en back-end. <br /> <br /> Na het succesvol afronden van dit project ben ik in dienst getreden bij United Games.",
    image: "/images/timeline/bluereaction.png",
    skills: ["HTML", "CSS", "JavaScript", "PHP", "MYSQL"],
  },
  {
    role: "Fullstack Developer",
    company: "United Games",
    title: Title.Employee,
    year: "2010 - 2013",
    summary:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae nulla vitae tortor viverra euismod. Morbi suscipit risus in maximus ultrices. Donec interdum purus augue, id venenatis tortor congue eget.",
    image: "/images/timeline/unitedgames.png",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "Symfony 2",
      "PHP",
      "MYSQL",
      "Memcached",
    ],
  },
  {
    role: "Mobile Developer",
    company: "Huckleberry Games",
    title: Title.CoFounder,
    year: "2013 - 2022",
    summary:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae nulla vitae tortor viverra euismod. Morbi suscipit risus in maximus ultrices. Donec interdum purus augue, id venenatis tortor congue eget.",
    image: "/images/timeline/huckleberry.png",
    skills: [
      "Java",
      "Kotlin",
      "Swift",
      "C#",
      "HTML",
      "CSS",
      "JavaScript",
      "PHP",
      "MYSQL",
      "NGINX",
    ],
  },
  {
    role: "Frontend Developer",
    company: "LabTrack",
    title: Title.CoFounder,
    year: "2022 - 2023",
    summary:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae nulla vitae tortor viverra euismod. Morbi suscipit risus in maximus ultrices. Donec interdum purus augue, id venenatis tortor congue eget.",
    image: "/images/timeline/labtrack.png",
    skills: ["HTML", "SASS", "Tailwind", "React", "Next.js"],
  },
];
