import { IconGitHub, IconInstagram, IconLinkedIn } from "../icons";
import "./socials-list.css";

const SocialsList = () => {
  return (
    <ul className="socials-list">
      <li>
        <a
          href="https://github.com/jur-wind/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconGitHub />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/in/jurwind/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconLinkedIn />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/jur.wind/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconInstagram />
        </a>
      </li>
    </ul>
  );
};

export default SocialsList;
