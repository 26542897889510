import { useContext } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import HeaderDark from "../../images/header-dark.png";
import HeaderLight from "../../images/header-light.png";
import { Theme, ThemeContext } from "../../theme";

import "./hero.css";

const Hero: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  return (
    <section className="hero-section">
      <div className="name">
        <Fade triggerOnce={true} direction="up" cascade={true}>
          <h2 className="title">{t("hero.name")}</h2>
          <h1 className="title">Jur Wind.</h1>
          <h2 className="title">{t("hero.title")}</h2>
        </Fade>
      </div>
      <div className="fadeInOnce">
        <img
          key={uuid()}
          alt="Header Picture"
          className={theme == Theme.Light ? "fadeIn" : "fadeOut"}
          src={HeaderLight}
        />
        <img
          key={uuid()}
          alt="Header Picture"
          className={theme == Theme.Dark ? "fadeIn" : "fadeOut"}
          src={HeaderDark}
        />
      </div>
    </section>
  );
};

export default Hero;
