import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./locale/en.json";
import nl from "./locale/nl.json";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      en: en,
      nl: nl,
    },
    fallbackLng: "en",
    supportedLngs: ["en", "nl"],
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false, // we do not use keys in form messages.welcome
  });

export default i18n;
