export const IconGitHub = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-4.466 19.59c-.405.078-.534-.171-.534-.384v-2.195c0-.747-.262-1.233-.55-1.481 1.782-.198 3.654-.875 3.654-3.947 0-.874-.312-1.588-.823-2.147.082-.202.356-1.016-.079-2.117 0 0-.671-.215-2.198.82-.64-.18-1.324-.267-2.004-.271-.68.003-1.364.091-2.003.269-1.528-1.035-2.2-.82-2.2-.82-.434 1.102-.16 1.915-.077 2.118-.512.56-.824 1.273-.824 2.147 0 3.064 1.867 3.751 3.645 3.954-.229.2-.436.552-.508 1.07-.457.204-1.614.557-2.328-.666 0 0-.423-.768-1.227-.825 0 0-.78-.01-.055.487 0 0 .525.246.889 1.17 0 0 .463 1.428 2.688.944v1.489c0 .211-.129.459-.528.385-3.18-1.057-5.472-4.056-5.472-7.59 0-4.419 3.582-8 8-8s8 3.581 8 8c0 3.533-2.289 6.531-5.466 7.59z" />
    </svg>
  );
};

export const IconLinkedIn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
    </svg>
  );
};

export const IconInstagram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
    </svg>
  );
};

export const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 256 200"
      fill="currentColor"
    >
      <g transform="matrix(1,0,0,0.97698158,0,7.6296401)">
        <path d="m 20.138271,155.9605 h 5.353559 v 28.59854 q 0,5.79737 -3.495083,9.07052 -3.467316,3.27319 -9.26472,3.27319 -6.0192959,0 -9.3756424,-3.07898 Q -9.36151e-6,190.74473 -9.36151e-6,185.19701 H 5.3258306 q 0,3.46737 1.8861946,5.40905 1.9140085,1.94172 5.5200018,1.94172 3.300909,0 5.32584,-2.0804 2.052638,-2.08042 2.080404,-5.82513 z" />
        <path d="m 63.049957,155.9605 v 27.46124 q -0.02698,5.71416 -3.606041,9.34792 -3.550556,3.63376 -9.653044,4.07761 l -1.414667,0.0553 q -6.629549,0 -10.568429,-3.57832 -3.938892,-3.57828 -3.994377,-9.84721 v -27.51672 h 5.270367 v 27.35031 q 0,4.3827 2.413243,6.82371 2.413255,2.41326 6.879196,2.41326 4.521414,0 6.906902,-2.41326 2.413303,-2.41329 2.413303,-6.79599 v -27.37803 z" />
        <path d="m 86.267182,180.00991 h -9.4866 v 16.33808 H 71.427023 V 155.9605 h 13.370008 q 6.82371,0 10.485224,3.10671 3.689245,3.10675 3.689245,9.0428 0,3.77248 -2.052649,6.57406 -2.02492,2.80163 -5.658679,4.18853 l 9.486598,17.14249 v 0.3329 h -5.714162 z m -9.4866,-4.35498 h 8.182892 q 3.966646,0 6.296698,-2.05264 2.35777,-2.05269 2.35777,-5.49228 0,-3.74472 -2.246848,-5.74189 -2.219093,-1.9972 -6.435339,-2.02492 h -8.155173 z" />
        <path d="m 129.20663,183.61595 0.77666,5.32579 1.13731,-4.79879 7.98873,-28.18245 h 4.49365 l 7.79457,28.18245 1.10955,4.88198 0.8599,-5.43675 6.26893,-27.62768 h 5.35356 l -9.79177,40.38749 h -4.85428 l -8.32157,-29.43072 -0.63797,-3.079 -0.63802,3.079 -8.62671,29.43072 h -4.85426 l -9.76402,-40.38749 h 5.32584 z" />
        <path d="m 176.58422,196.34799 h -5.32579 V 155.9605 h 5.32579 z" />
        <path d="m 217.3323,196.34799 h -5.35356 l -20.33244,-31.1228 v 31.1228 h -5.35355 V 155.9605 h 5.35355 l 20.38793,31.26144 V 155.9605 h 5.29807 z" />
        <path d="M 226.79118,196.34799 V 155.9605 h 11.40058 q 5.27035,0 9.3202,2.33005 4.04984,2.33002 6.24117,6.6295 2.21914,4.2995 2.24686,9.87499 v 2.57969 q 0,5.71416 -2.2191,10.01366 -2.19137,4.29949 -6.29669,6.60178 -4.07757,2.30233 -9.51432,2.35782 z m 5.3258,-36.00479 v 31.6498 h 5.60325 q 6.15797,0 9.5698,-3.82795 3.4396,-3.82793 3.4396,-10.90128 V 174.906 q 0,-6.8792 -3.24543,-10.6794 -3.21767,-3.82792 -9.15372,-3.8834 z" />
      </g>
      <path d="M 128.70739,-4.0589259e-6 A 68.27504,68.27504 0 0 0 71.685858,30.785868 H 185.76376 A 68.27504,68.27504 0 0 0 128.70739,-4.0589259e-6 Z M 70.364903,32.886859 a 68.27504,68.27504 0 0 0 -9.931528,35.387161 68.27504,68.27504 0 0 0 9.502041,34.7023 c 2.019095,0.26485 4.216936,0.0511 6.607096,0.0511 4.470017,0 8.292733,-1.56884 11.466055,-4.708076 3.139232,-3.173322 4.708075,-6.979099 4.708075,-11.414991 V 32.886895 Z m 25.06561,0 v 54.017468 c 0,5.18658 -1.859356,9.621843 -5.578649,13.307043 -1.469046,1.46905 -3.058477,2.6453 -4.766104,3.52874 h 40.54096 L 100.19663,32.886859 Z m 7.603037,0 24.53862,68.503841 14.12653,-39.668053 -10.43066,-28.835788 z m 31.07606,0 9.0238,24.944893 9.02381,-24.944893 z m 20.88684,0 -10.43065,28.835788 14.12654,39.668053 24.5897,-68.503841 z m 31.12482,0 -25.43242,70.853251 h 26.31461 a 68.27504,68.27504 0 0 0 9.98027,-35.46609 68.27504,68.27504 0 0 0 -9.88974,-35.387161 z m -42.98786,32.777747 -13.56705,38.075504 h 27.18285 z M 71.734604,105.8411 a 68.27504,68.27504 0 0 0 56.972786,30.70693 68.27504,68.27504 0 0 0 56.97511,-30.70693 z" />
    </svg>
  );
};

export const IconThemeLight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 48 48"
      fill="currentColor"
    >
      <path d="M24 31q2.9 0 4.95-2.05Q31 26.9 31 24q0-2.9-2.05-4.95Q26.9 17 24 17q-2.9 0-4.95 2.05Q17 21.1 17 24q0 2.9 2.05 4.95Q21.1 31 24 31Zm0 3q-4.15 0-7.075-2.925T14 24q0-4.15 2.925-7.075T24 14q4.15 0 7.075 2.925T34 24q0 4.15-2.925 7.075T24 34ZM3.5 25.5q-.65 0-1.075-.425Q2 24.65 2 24q0-.65.425-1.075Q2.85 22.5 3.5 22.5h5q.65 0 1.075.425Q10 23.35 10 24q0 .65-.425 1.075-.425.425-1.075.425Zm36 0q-.65 0-1.075-.425Q38 24.65 38 24q0-.65.425-1.075.425-.425 1.075-.425h5q.65 0 1.075.425Q46 23.35 46 24q0 .65-.425 1.075-.425.425-1.075.425ZM24 10q-.65 0-1.075-.425Q22.5 9.15 22.5 8.5v-5q0-.65.425-1.075Q23.35 2 24 2q.65 0 1.075.425.425.425.425 1.075v5q0 .65-.425 1.075Q24.65 10 24 10Zm0 36q-.65 0-1.075-.425-.425-.425-.425-1.075v-5q0-.65.425-1.075Q23.35 38 24 38q.65 0 1.075.425.425.425.425 1.075v5q0 .65-.425 1.075Q24.65 46 24 46ZM12 14.1l-2.85-2.8q-.45-.45-.425-1.075.025-.625.425-1.075.45-.45 1.075-.45t1.075.45L14.1 12q.4.45.4 1.05 0 .6-.4 1-.4.45-1.025.45-.625 0-1.075-.4Zm24.7 24.75L33.9 36q-.4-.45-.4-1.075t.45-1.025q.4-.45 1-.45t1.05.45l2.85 2.8q.45.45.425 1.075-.025.625-.425 1.075-.45.45-1.075.45t-1.075-.45ZM33.9 14.1q-.45-.45-.45-1.05 0-.6.45-1.05l2.8-2.85q.45-.45 1.075-.425.625.025 1.075.425.45.45.45 1.075t-.45 1.075L36 14.1q-.4.4-1.025.4-.625 0-1.075-.4ZM9.15 38.85q-.45-.45-.45-1.075t.45-1.075L12 33.9q.45-.45 1.05-.45.6 0 1.05.45.45.45.45 1.05 0 .6-.45 1.05l-2.8 2.85q-.45.45-1.075.425-.625-.025-1.075-.425ZM24 24Z" />
    </svg>
  );
};

export const IconThemeDark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 48 48"
      fill="currentColor"
    >
      <path d="M24 42q-7.5 0-12.75-5.25T6 24q0-7.5 5.25-12.75T24 6q.4 0 .85.025.45.025 1.15.075-1.8 1.6-2.8 3.95-1 2.35-1 4.95 0 4.5 3.15 7.65Q28.5 25.8 33 25.8q2.6 0 4.95-.925T41.9 22.3q.05.6.075.975Q42 23.65 42 24q0 7.5-5.25 12.75T24 42Zm0-3q5.45 0 9.5-3.375t5.05-7.925q-1.25.55-2.675.825Q34.45 28.8 33 28.8q-5.75 0-9.775-4.025T19.2 15q0-1.2.25-2.575.25-1.375.9-3.125-4.9 1.35-8.125 5.475Q9 18.9 9 24q0 6.25 4.375 10.625T24 39Zm-.2-14.85Z" />
    </svg>
  );
};

export const IconLanguage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 48 48"
      fill="currentColor"
    >
      <path d="M24 44q-4.2 0-7.85-1.575Q12.5 40.85 9.8 38.15q-2.7-2.7-4.25-6.375Q4 28.1 4 23.9t1.55-7.825Q7.1 12.45 9.8 9.75t6.35-4.225Q19.8 4 24 4q4.2 0 7.85 1.525Q35.5 7.05 38.2 9.75q2.7 2.7 4.25 6.325Q44 19.7 44 23.9t-1.55 7.875Q40.9 35.45 38.2 38.15t-6.35 4.275Q28.2 44 24 44Zm0-2.9q1.75-1.8 2.925-4.125Q28.1 34.65 28.85 31.45H19.2q.7 3 1.875 5.4Q22.25 39.25 24 41.1Zm-4.25-.6q-1.25-1.9-2.15-4.1-.9-2.2-1.5-4.95H8.6Q10.5 35 13 37.025q2.5 2.025 6.75 3.475Zm8.55-.05q3.6-1.15 6.475-3.45 2.875-2.3 4.625-5.55h-7.45q-.65 2.7-1.525 4.9-.875 2.2-2.125 4.1Zm-20.7-12h7.95q-.15-1.35-.175-2.425-.025-1.075-.025-2.125 0-1.25.05-2.225.05-.975.2-2.175h-8q-.35 1.2-.475 2.15T7 23.9q0 1.3.125 2.325.125 1.025.475 2.225Zm11.05 0H29.4q.2-1.55.25-2.525.05-.975.05-2.025 0-1-.05-1.925T29.4 19.5H18.65q-.2 1.55-.25 2.475-.05.925-.05 1.925 0 1.05.05 2.025.05.975.25 2.525Zm13.75 0h8q.35-1.2.475-2.225Q41 25.2 41 23.9q0-1.3-.125-2.25T40.4 19.5h-7.95q.15 1.75.2 2.675.05.925.05 1.725 0 1.1-.075 2.075-.075.975-.225 2.475Zm-.5-11.95h7.5q-1.65-3.45-4.525-5.75Q32 8.45 28.25 7.5q1.25 1.85 2.125 4t1.525 5Zm-12.7 0h9.7q-.55-2.65-1.85-5.125T24 7q-1.6 1.35-2.7 3.55-1.1 2.2-2.1 5.95Zm-10.6 0h7.55q.55-2.7 1.4-4.825.85-2.125 2.15-4.125-3.75.95-6.55 3.2T8.6 16.5Z" />
    </svg>
  );
};

export const IconMenu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 48 48"
      fill="currentColor"
    >
      <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" />
    </svg>
  );
};
