import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconThemeDark, IconThemeLight, Logo } from "../../components/icons";
import { Theme, ThemeContext } from "../../theme";
import "./header.css";

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [menu, setMenu] = useState(false);
  const [activeId, setActiveId] = useState("home");
  const [didScroll, setDidScroll] = useState(false);
  const { theme, toggle } = useContext(ThemeContext);

  const menuItems: { [key: string]: string } = {
    home: t("menu.about"),
    portfolio: t("menu.portfolio"),
    timeline: t("menu.timeline"),
    contact: t("menu.contact"),
  };

  useEffect(() => {
    document.body.style.overflow = menu ? "hidden" : "unset";
  }, [menu]);

  useEffect(() => {
    const listener = () => {
      let id = null;
      const scrollDistance =
        document.body.scrollTop || document.documentElement.scrollTop;
      const header = document.getElementById("header");
      const headerRect = header?.getBoundingClientRect();

      Object.keys(menuItems).forEach((index) => {
        const element = document.getElementById(index);
        if (element && headerRect) {
          const elementRect = element.getBoundingClientRect();
          if (elementRect.top - headerRect.height <= 10) {
            id = index;
          }
        }
      });

      setActiveId(id ?? "home");
      setDidScroll(scrollDistance > 40);
    };

    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  const onMenuItemClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    key: string
  ) => {
    setMenu(false);
    const element = document.getElementById(key);
    const header = document.getElementById("header")?.getBoundingClientRect();
    if (header) {
      window.scrollTo(0, element ? element.offsetTop - header.height + 20 : 0);
    }
    e.preventDefault();
  };

  const onChangeLanguage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    i18n.changeLanguage(i18n.language == "en" ? "nl" : "en");
    e.preventDefault();
  };

  const onChangeTheme = (e: React.MouseEvent<HTMLAnchorElement>) => {
    toggle();
    e.preventDefault();
  };

  const onMenuClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setMenu(!menu);
    e.preventDefault();
  };

  return (
    <header
      id="header"
      className={`${!didScroll ? "header" : "header-scroll"}`}
    >
      {menu && (
        <div className="submenu slide-in container">
          <div className="icons">
            <a href="" onClick={(e) => onChangeTheme(e)}>
              {theme == Theme.Light ? <IconThemeLight /> : <IconThemeDark />}
            </a>
          </div>
          <ul className="list">
            {Object.keys(menuItems).map((key, index) => (
              <li key={index}>
                <a
                  href=""
                  onClick={(e) => onMenuItemClick(e, key)}
                  className={activeId == key ? "active" : ""}
                >
                  {menuItems[key]}
                </a>
              </li>
            ))}
          </ul>
          <div className="lang">
            <a href="" onClick={(e) => onChangeLanguage(e)}>
              {i18n.language == "en" ? "Nederlands" : "English"}
            </a>
          </div>
        </div>
      )}

      <div className="container">
        <div className="top">
          <div className="logo">
            <a href="/">
              <Logo />
            </a>
          </div>
          <div className="menu">
            <a href="" onClick={(e) => onMenuClick(e)}>
              <div className={`menu-icon ${menu ? "open" : ""}`}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>
        <nav className="links">
          <ul>
            {Object.keys(menuItems).map((key, index) => (
              <li key={index}>
                <a
                  href=""
                  onClick={(e) => onMenuItemClick(e, key)}
                  className={activeId == key ? "active" : ""}
                >
                  {menuItems[key]}
                </a>
              </li>
            ))}
            <li className="icon">
              <a href="" onClick={(e) => onChangeLanguage(e)}>
                {i18n.language}
              </a>
              <a href="" onClick={(e) => onChangeLanguage(e)}>
                {i18n.language == "en" ? "nl" : "en"}
              </a>
            </li>
            <li className="icon">
              <a href="" onClick={(e) => onChangeTheme(e)}>
                {theme == Theme.Light ? <IconThemeLight /> : <IconThemeDark />}
              </a>
              <a href="" onClick={(e) => onChangeTheme(e)}>
                {theme == Theme.Light ? <IconThemeDark /> : <IconThemeLight />}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
