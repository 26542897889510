import SocialsList from "../../components/socials-list/socials-list";
import "./footer.css";

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="content">
        <div className="container">
          <p>Copyright &copy; {new Date().getFullYear()} Jur Wind.</p>
          <SocialsList />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
