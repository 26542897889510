import "./quote.css";

export interface QuoteProps {
  id?: string;
  title?: string;
  text: string;
}

const Quote: React.FC<QuoteProps> = ({ id = "", title, text }) => {
  return (
    <section id={id} className="quote">
      <div className="container">
        {title && <h1 className="title">{title}</h1>}
        <p>{text}</p>
      </div>
    </section>
  );
};

export default Quote;
