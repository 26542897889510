export enum ProjectType {
  Web,
  Mobile,
}

export enum ProjectStatus {
  Active = "active",
  Inactive = "inactive",
}

export interface Project {
  id: string;
  year: number;
  type: ProjectType;
  status: ProjectStatus;
  googlePlayStore?: string;
  appleAppStore?: string;
  image: string;
  url?: string;
}

export const Projects = [
  {
    id: "metra",
    year: 2022,
    type: ProjectType.Web,
    status: ProjectStatus.Active,
    url: "https://metra.bio",
    image: "/images/projects/metra.jpg",
  },
  {
    id: "wordon",
    year: 2012,
    type: ProjectType.Mobile,
    status: ProjectStatus.Active,
    googlePlayStore:
      "https://play.google.com/store/apps/details?id=air.com.flaregames.wordon",
    appleAppStore: "https://apps.apple.com/app/id565881351",
    image: "/images/projects/wordon.jpg",
  },
  {
    id: "wordonblast",
    year: 2020,
    type: ProjectType.Mobile,
    status: ProjectStatus.Active,
    googlePlayStore:
      "https://play.google.com/store/apps/details?id=nl.hbgames.wordonblast",
    appleAppStore: "https://apps.apple.com/app/id1547986795",
    image: "/images/projects/wordon-blast.jpg",
  },
  {
    id: "polyisland",
    year: 2019,
    type: ProjectType.Mobile,
    status: ProjectStatus.Active,
    googlePlayStore:
      "https://play.google.com/store/apps/details?id=nl.hbgames.polyisland",
    appleAppStore: "https://apps.apple.com/app/id1448250507",
    image: "/images/projects/polyisland.jpg",
  },
  {
    id: "pluszle",
    year: 2018,
    type: ProjectType.Mobile,
    status: ProjectStatus.Active,
    googlePlayStore:
      "https://play.google.com/store/apps/details?id=com.pluszle.game",
    appleAppStore: "https://apps.apple.com/app/id1327839430",
    image: "/images/projects/pluszle.jpg",
  },
  {
    id: "qwhizzle",
    year: 2013,
    type: ProjectType.Mobile,
    status: ProjectStatus.Inactive,
    googlePlayStore: undefined,
    appleAppStore: undefined,
    image: "/images/projects/qwhizzle.jpg",
  },
  {
    id: "vorg",
    year: 2012,
    type: ProjectType.Web,
    status: ProjectStatus.Inactive,
    url: undefined,
    image: "/images/projects/vorg.jpg",
  },
  {
    id: "rutt",
    year: 2011,
    type: ProjectType.Web,
    status: ProjectStatus.Inactive,
    url: undefined,
    image: "/images/projects/rutt.jpg",
  },
  {
    id: "klmopengame",
    year: 2010,
    type: ProjectType.Web,
    status: ProjectStatus.Inactive,
    url: undefined,
    image: "/images/projects/klmopen.jpg",
  },
];
